// Libs
import { TranslateResult } from "vue-i18n";
import { Location } from "vue-router";

// Locale
import { i18n } from "../locale";

// Menu
import menu from "../../resources/menu.json";

type positon = "left" | "right";
interface IDivider {
  divider: true;
}

interface IJsonMenu {
  route: string;
  name: string;
  position?: positon;
  children?: jsonMenuType[];
}

export interface IMenu {
  route: Location;
  title: TranslateResult;
  children?: menuType[];
}

export interface ITree {
  route: Location;
  title: TranslateResult;
  children?: (ITree)[];
}

type jsonMenuType = IJsonMenu | IDivider;
export type menuType = IMenu | IDivider;

export class MenuService {
  public get jsonMenu(): jsonMenuType[] {
    return [
      ...menu as jsonMenuType[],
    ];
  }

  public get tree(): ITree[] {
    return this.mapToTree(this.jsonMenu);
  }
  public get leftMenu(): menuType[] {
    return this.mapToMenu(this.jsonMenu, "left");
  }
  public get rightMenu(): menuType[] {
    return this.mapToMenu(this.jsonMenu, "right");
  }

  private mapToMenu(menu: jsonMenuType[], positon: positon | null): menuType[] {
    return menu.filter((menu: jsonMenuType) => "divider" in menu || menu.position === positon || positon === null)
      .map((menu: jsonMenuType) => ({
        ...("divider" in menu ? {
          divider: menu.divider,
        } : {
            route: { name: menu.route },
            title: i18n.t(menu.name),
            ...(menu.children !== undefined ? { children: this.mapToMenu(menu.children, null) } : undefined)
          })
      }));
  }
  private mapToTree(menu: jsonMenuType[]): ITree[] {
    return menu.filter((menu: jsonMenuType) => ("divider" in menu) === false)
      .map((menu: jsonMenuType) => {
        if (("divider" in menu)) { throw new Error("Unable to parse menu"); }
        return {
          route: { name: menu.route },
          title: i18n.t(menu.name),
          ...(menu.children !== undefined ? { children: this.mapToTree(menu.children) } : undefined)
        };
      });
  }
}
