import VueI18n from "vue-i18n";
import en from "../resources/locale/en.json";
import sv from "../resources/locale/sv.json";

export const i18n = new VueI18n({
  locale: "en", // set locale,
  messages: {
    en,
    sv,
  }
});