export type callback<T = any> = (payload?: T) => void;

export class BroadcastService {
  private observers: { [key: string]: callback[] } = {};

  public subscribe<T>(name: string, callback?: callback<T>) {
    if (callback === undefined) {
      callback = () => { /* */ };
    }
    // Get observer if exists
    let observer = this.getObserver(name);
    if (observer === undefined) {
      // Create observer and add callback
      this.observers[name] = [callback];
      // Update observer reference
      observer = this.observers[name];
    } else {
      // Observer exists, push callback to it
      observer.push(callback);
    }

    return {
      unsubscribe: ((observerName: string, callback: callback) => () => {
        // Get observer by name
        const observer = this.getObserver(observerName);
        if (observer !== undefined) {
          // Find current callback index
          const callbackIndex: number = observer.findIndex((findCallback: callback) => findCallback === callback);
          if (callbackIndex !== -1) {
            // Remove callback from observers
            observer.splice(callbackIndex, 1);
          }
        }
      })(name, callback)
    }
  }
  public emit<T>(name: string, payload?: T) {
    // Find observer
    const observer = this.getObserver(name);
    if (observer !== undefined) {
      // Loop through and execut each callback
      observer.forEach((observer: callback) => observer(payload));
    }
  }
  private getObserver(key: string): callback[] | undefined {
    return key in this.observers && this.observers[key] !== null ? this.observers[key] : undefined;
  }
}
