






















// Libs
import { Vue, Component, Prop } from "vue-property-decorator";

// Modules
import { RandomModule } from "../../../shared/modules/random.module";

export enum inViewPosition {
  TOP = "top",
  ANCHOR = "anchor",
  BOTTOM = "bottom",
}

@Component
export default class InViewComponent extends Vue {
  @Prop()
  public name!: string;
  @Prop({ default: true })
  public showAnchor!: boolean;
  @Prop({ default: "0px" })
  public offset!: string;

  public inViewPosition: typeof inViewPosition = inViewPosition;

  public emit(position: inViewPosition) {
    this.$emit("in-view", { name: this.name, position });
  }
}
