






































// Libs
import { Vue, Component } from "vue-property-decorator";

// Components
import FadeinComponent from "./fadein.component.vue";
import InViewComponent from "./in-view.component.vue";

@Component({ components: { InViewComponent, FadeinComponent } })
export default class PortfolioComponent extends Vue {}
