































// Libs
import { Vue, Component } from "vue-property-decorator";

// Services
import { BroadcastService } from "../services/broadcast.service";

// Components
import FadeinComponent from "./fadein.component.vue";
import InViewComponent from "./in-view.component.vue";

// Bootstrap
import { broadcastService } from "../bootstrap";

@Component({ components: { InViewComponent, FadeinComponent } })
export default class AboutComponent extends Vue {
  private readonly broadcastService: BroadcastService = broadcastService;

  public scrollY: number = 0;

  public created() {
    this.broadcastService.subscribe("scrollY", (value?: number) => {
      if (value !== undefined) {
        this.scrollY = value;
      }
    });
  }
}
