


























// Libs
import { Vue, Component } from "vue-property-decorator";

// Services
import { BroadcastService } from "../services/broadcast.service";

// Bootstrap
import { broadcastService } from "../bootstrap";

// Components
import InViewComponent from "./in-view.component.vue";

@Component({ components: { InViewComponent } })
export default class ContactComponent extends Vue {
  public inView(inView: boolean) {
    if (inView === true) {
      this.$emit("inView", "contact");
    }
  }
  private readonly broadcastService: BroadcastService = broadcastService;

  private baseData = {
    xl: {
      imageHeight: 2555,
      contentHeight: 900,
      scrollStart: 7400,
    },
    lg: {
      imageHeight: 1855,
      contentHeight: 900,
      scrollStart: 5000,
    },
  };

  private polygonStartPos(size: {
    imageHeight: number;
    scrollStart: number;
  }): number {
    if (this.scrollY <= size.scrollStart) {
      return size.imageHeight - 350;
    }
    if (this.scrollY > size.scrollStart + 600) {
      return size.imageHeight;
    }
    return Math.round(
      size.imageHeight - 350 * ((size.scrollStart + 600 - this.scrollY) / 600)
    );
  }

  private polygonStartPos2(size: { scrollStart: number }): number {
    if (this.scrollY <= size.scrollStart) {
      return 0;
    }
    if (this.scrollY > size.scrollStart + 600) {
      return 350;
    }
    return Math.round(
      350 - 350 * ((size.scrollStart + 600 - this.scrollY) / 600)
    );
  }

  public get cssVars() {
    return {
      "--contact-height-xl": `${
        this.baseData.xl.imageHeight + this.baseData.xl.contentHeight - 340
      }px`,
      "--contact-height-lg": `${
        this.baseData.lg.imageHeight + this.baseData.lg.contentHeight - 340
      }px`,
      "--image-height-xl": `${this.baseData.xl.imageHeight}px`,
      "--image-height-lg": `${this.baseData.lg.imageHeight}px`,
      "--content-height-xl": `${this.baseData.xl.contentHeight}px`,
      "--content-height-lg": `${this.baseData.lg.contentHeight}px`,
      "--polygon-start-pos-xl": `${this.polygonStartPos(this.baseData.xl)}px`,
      "--polygon-start-pos-lg": `${this.polygonStartPos(this.baseData.lg)}px`,
      "--polygon-start-pos2-xl": `${this.polygonStartPos2(this.baseData.xl)}px`,
      "--polygon-start-pos2-lg": `${this.polygonStartPos2(this.baseData.lg)}px`,
      "--polygon-start-pos3-xl": `${this.baseData.xl.imageHeight - 350}px`,
      "--polygon-start-pos3-lg": `${this.baseData.lg.imageHeight - 350}px`,
    };
  }

  public scrollY: number = 0;

  public created() {
    this.broadcastService.subscribe("scrollY", (value?: number) => {
      if (value !== undefined) {
        this.scrollY = value;
      }
    });
  }
}
