import VueRouter, { Route } from "vue-router";

export const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/about", name: "about" },
    { path: "/portfolio", name: "portfolio" },
    { path: "/contact", name: "contact" },
  ],
  scrollBehavior(to: Route, from: Route, savedPosition?: { x: number, y: number }) {
    if (savedPosition !== undefined) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
  }
});