
























// Libs
import { Vue, Component, Watch } from "vue-property-decorator";

// Services
import { ITree, MenuService, menuType } from "../services/menu.service";
import { BroadcastService } from "../services/broadcast.service";

// Bootstrap
import { menuService, broadcastService } from "../bootstrap";

interface IMenuItemWithShow extends ITree {
  show: boolean;
}

@Component
export default class HeaderComponent extends Vue {
  private readonly menuService: MenuService = menuService;
  private readonly broadcastService: BroadcastService = broadcastService;
  public get menu(): menuType[] {
    return [...this.menuService.leftMenu, ...this.menuService.rightMenu];
  }

  public scrollY: number = 0;

  public created() {
    this.broadcastService.subscribe("scrollY", (value?: number) => {
      if (value !== undefined) {
        this.scrollY = value;
      }
    });
  }

  private baseData = {
    xl: {
      navbar: {
        unFoldedHeight: 490,
        foldedHeight: 100,
        unFoldedPaddingTitle: 90,
        foldedPaddingTitle: 10,
        unFoldedPaddingMenu: 90,
        foldedPaddingMenu: 25,
      },
    },
    lg: {
      navbar: {
        unFoldedHeight: 300,
        foldedHeight: 60,
        unFoldedPaddingTitle: 50,
        foldedPaddingTitle: 2,
        unFoldedPaddingMenu: 50,
        foldedPaddingMenu: 10,
      },
    },
  };
  private navbarHeight(size: {
    unFoldedHeight: number;
    foldedHeight: number;
  }): number {
    return size.unFoldedHeight - this.scrollY > size.foldedHeight
      ? size.unFoldedHeight - this.scrollY
      : size.foldedHeight;
  }
  private polygonStartPos(size: {
    unFoldedHeight: number;
    foldedHeight: number;
  }): number {
    const navbarHeight = this.navbarHeight(size);
    return navbarHeight / 2 > size.foldedHeight
      ? navbarHeight / 2
      : size.foldedHeight;
  }

  private navbarPaddingTop(size: {
    unFoldedHeight: number;
    foldedHeight: number;
    unFoldedPadding: number;
    foldedPadding: number;
  }): number {
    const navbarHeight = this.navbarHeight(size);
    if (navbarHeight > size.unFoldedHeight) {
      return size.foldedPadding;
    }
    const percentage =
      (navbarHeight - size.foldedHeight) /
      (size.unFoldedHeight - size.foldedHeight);
    return Math.round(
      percentage * (size.unFoldedPadding - size.foldedPadding) +
        size.foldedPadding
    );
    return size.unFoldedHeight - size.foldedHeight;
    return navbarHeight > size.foldedHeight
      ? size.foldedPadding +
          Math.round(
            (size.unFoldedPadding - size.foldedPadding) *
              (navbarHeight / size.unFoldedHeight)
          )
      : size.foldedPadding;
  }

  private navbarHeadPaddingTop(size: {
    unFoldedHeight: number;
    foldedHeight: number;
    unFoldedPaddingTitle: number;
    foldedPaddingTitle: number;
  }): number {
    return this.navbarPaddingTop({
      unFoldedHeight: size.unFoldedHeight,
      foldedHeight: size.foldedHeight,
      unFoldedPadding: size.unFoldedPaddingTitle,
      foldedPadding: size.foldedPaddingTitle,
    });
  }
  private navbarMenuPaddingTop(size: {
    unFoldedHeight: number;
    foldedHeight: number;
    unFoldedPaddingMenu: number;
    foldedPaddingMenu: number;
  }): number {
    return this.navbarPaddingTop({
      unFoldedHeight: size.unFoldedHeight,
      foldedHeight: size.foldedHeight,
      unFoldedPadding: size.unFoldedPaddingMenu,
      foldedPadding: size.foldedPaddingMenu,
    });
  }

  public get cssVars() {
    return {
      "--navbar-height-xl": `${this.navbarHeight(this.baseData.xl.navbar)}px`,
      "--navbar-height-lg": `${this.navbarHeight(this.baseData.lg.navbar)}px`,
      "--polygon-start-pos-xl": `${this.polygonStartPos(
        this.baseData.xl.navbar
      )}px`,
      "--polygon-start-pos-lg": `${this.polygonStartPos(
        this.baseData.lg.navbar
      )}px`,
      "--navbar__head-padding-top-xl": `${this.navbarHeadPaddingTop(
        this.baseData.xl.navbar
      )}px`,
      "--navbar__head-padding-top-lg": `${this.navbarHeadPaddingTop(
        this.baseData.lg.navbar
      )}px`,
      "--navbar__menu-padding-top-xl": `${this.navbarMenuPaddingTop(
        this.baseData.xl.navbar
      )}px`,
      "--navbar__menu-padding-top-lg": `${this.navbarMenuPaddingTop(
        this.baseData.lg.navbar
      )}px`,
    };
  }
}
