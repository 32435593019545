










// Libs
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class FadeinComponent extends Vue {
  public elementInViewPort = false;

  public visibilityChanged(isVisible: boolean) {
    this.elementInViewPort = isVisible;
  }
}
