












// Libs
import { Vue, Component, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

// Services
import { BroadcastService } from "../services/broadcast.service";

// Components
import FooterComponent from "./footer.component.vue";
import HeaderComponent from "./header.component.vue";
import PortfolioComponent from "./portfolio.component.vue";
import ContactComponent from "./contact.component.vue";
import AboutComponent from "./about.component.vue";
import InViewComponent, { inViewPosition } from "./in-view.component.vue";

// Bootstrap
import { broadcastService } from "../bootstrap";

@Component({
  components: {
    FooterComponent,
    HeaderComponent,
    PortfolioComponent,
    ContactComponent,
    AboutComponent,
    InViewComponent,
  },
})
export default class IndexComponent extends Vue {
  public broadcastService: BroadcastService = broadcastService;

  @Watch("route")
  onRouteChange = (route: Route) => {
    const element: Element | null = document.querySelector(
      `a[name=${route.name}]`
    );
    if (element !== null && "programatic" in route.params === false) {
      element.scrollIntoView();
    }
  };

  public get route(): Route {
    return this.$route;
  }

  public scrollY: number = 0;

  public created() {
    this.broadcastService.emit("scrollY", window.scrollY);
    window.addEventListener("scroll", (e: any) => {
      this.broadcastService.emit("scrollY", window.scrollY);
    });
    this.broadcastService.subscribe("scrollY", (value?: number) => {
      if (value !== undefined) {
        this.scrollY = value;
      }
    });
  }

  public inView(event: { name: string; position: inViewPosition }) {
    if (
      [inViewPosition.ANCHOR, inViewPosition.BOTTOM].includes(
        event.position
      ) === true &&
      this.$route.name !== event.name
    ) {
      this.$router.push({
        name: event.name,
        params: { programatic: "true" },
      });
    }
  }
}
