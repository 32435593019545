














// Libs
import { Vue, Component, Watch } from "vue-property-decorator";

@Component
export default class FooterComponent extends Vue {
  public stripPhoneFormat(phone: string) {
    return phone
      .replace(/[^\d+]/g, "")
      .replace(/^(\+\d{1,3})0/, "$1")
      .replace(/^0/, "+46");
  }
}
